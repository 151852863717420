import * as Sentry from '@sentry/browser'
import React from 'react'
import useTranslation from '@/i18n'
import Link from '@/components/Link'

const Failed = () => {
  const { t } = useTranslation()
  return (
    <main className="flex items-center justify-center h-screen px-4 bg-gray-100">
      <div className="flex flex-col items-center justify-center sm:flex-row sm:items-center">
        <div className="sm:ml-4 text-center sm:text-left">
          <h1 className="flex justify-center sm:justify-start items-center">
            <span className="text-2xl font-medium text-gray-600 sm:text-3xl">
              {t('Something happend')}
            </span>
          </h1>
          <p className="text-base font-normal text-gray-600">
            {t('Sorry for inconvenient. Please retry later')}
          </p>

          <p className="text-base font-normal text-gray-600">
            {t('Go back to')}
            <a href="/" className="border-b text-blue-500 ml-1">
              Home
            </a>
          </p>
        </div>
      </div>
    </main>
  )
}

export default Failed
